import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import videoTable from '../constants/videoTable'

const Play = ({ location }) => {
  const [videoLink, setVideoLink] = useState('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const videoId = Number(urlParams.get('id'))

    if (!videoId) {
      return
    }

    videoTable.forEach((video) => {
      if (video.id === videoId) {
        setVideoLink(video.youtubeLink)
      }
    })
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Videos</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <div className='mx-auto max-w-5xl'>
        <div className='relative' style={{ paddingTop: '56.25%' }}>
          <iframe
            width='100%'
            height='100%'
            className='absolute inset-0 w-full h-full'
            src={videoLink}
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='my-8 ml-4 md:ml-8 lg:mx-40 xl:mx-72'>
        <Link to='/videos'>←Back to Videos</Link>
      </div>
    </Layout>
  )
}

export default Play
